/**
 * @generated SignedSource<<67ecb5130c61161cd8ec03dbb1291b81>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RegionAutocomplete_regions$data = {
  readonly cloudRegions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly region: string;
      };
    }>;
  };
  readonly " $fragmentType": "RegionAutocomplete_regions";
};
export type RegionAutocomplete_regions$key = {
  readonly " $data"?: RegionAutocomplete_regions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RegionAutocomplete_regions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterElement"
    },
    {
      "defaultValue": 100,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "RegionAutocomplete_regions",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filterElement",
          "variableName": "filterElement"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "concreteType": "CloudRegionConnection",
      "kind": "LinkedField",
      "name": "cloudRegions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CloudRegionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CloudRegion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "region",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "ef66096b7e87b4b86390633ed5e2e37b";

export default node;
